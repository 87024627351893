import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ShopLayout from "../components/new-layout";
import Breadcrumbs from "../components/breadcrumbs";
import Newsletter from "../components/shop-newsletter";
import Paws from "../components/paws";
import Img from "gatsby-image";

const About = () => {
  const data = useStaticQuery(graphql`
    query aboutData {
      about: file(relativePath: { eq: "sobre.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const aboutImage = data.about.childImageSharp.fluid;

  let links = [
    ["/platform", "Home"],
    ["/about", "About Meia Pata"],
  ];

  return (
    <ShopLayout>
      <Breadcrumbs links={links} />
      <div className="relative">
        <div className="absolute top-0 bottom-0 right-0 left-0 overflow-hidden z-0">
          <Paws
            className="fill-current text-yellow-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "19deg",
              right: "10%",
              top: 20,
            }}
          />
          <Paws
            className="fill-current text-pink-500 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "-10deg",
              right: "45%",
              top: 60,
            }}
          />
          <Paws
            className="fill-current text-blue-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "-40deg",
              left: "1%",
              top: 200,
            }}
          />
          <Paws
            className="fill-current text-blue-100 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "-40deg",
              left: "44%",
              top: 700,
            }}
          />
          <Paws
            className="fill-current text-pink-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "27deg",
              right: "2%",
              top: 800,
            }}
          />
          <Paws
            className="fill-current text-yellow-500 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "27deg",
              left: "24%",
              top: 1250,
            }}
          />
        </div>
        <div className="container lg:pl-12 my-8 lg:my-44 relative">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-16">
            <div className="relative w-full h-96 lg:h-full">
              <Img
                style={{ height: "100%", width: "100%" }}
                imgStyle={{ objectFit: "cover" }}
                className="object-cover object-top rounded-xl"
                fluid={aboutImage}
              />
            </div>
            <div>
              <h1 className="font-display text-blue-900 text-4xl mb-6">
                About Meia Pata
              </h1>
              <p className="text-blue-700 text-lg mb-4 lg:max-w-sm">
                Meia Pata is an international brand that sells children's goods
                100% conceived and produced in Portugal. Annualy launches 2
                Collections presenting brand new styles of socks, tights, shoes
                and accessories for children from newborn babies to 12 year old.
              </p>
              <p className="text-blue-700 text-lg lg:max-w-sm">
                With an innovative design and high quality products, creates
                styles based on a Colour Card composed of more than 50 colour
                tones and 100 different models.
                <br />
                As a complement to their usual collections, Meia Pata releases,
                in Summer, a complete Swimwear Line and, in Winter, a complete
                collection of winter clothes such as coats, caps and others.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-green-100 py-10 lg:py-20 mb-14 lg:mb-28 relative">
          <div className="container md:pl-12">
            <h1 className="font-display text-blue-900 text-4xl mb-6">
              Meia Pata around the Globe
            </h1>
            <p className="text-blue-700 text-lg mb-4 max-w-screen-lg">
              Meia Pata™️ currently operates in several countries, listed below:
              <br />
              Portugal, Spain, Belgium, Netherlands, United Kingdom, Italy,
              Greece, Japan, Dubai, Saudi Arabia, Lebanon, Oman, Panama,
              Dominican Republic, Colombia, USA, Chile, Ecuador, Mexico, Norway,
              Russia, Poland and Kuwait.
            </p>
            <p className="text-blue-700 text-lg">
              Do you want to get in touch with one of our agents? Reach us via
              email at:{" "}
              <a
                href="mailto:hello@meiapata.pt"
                className="font-semibold"
                style={{ textTransform: "none" }}
              >
                hello@meiapata.pt
              </a>
            </p>
          </div>
        </div>
      </div>
      <Newsletter />
    </ShopLayout>
  );
};

export default About;
